@import "styles/utils/mixins";

.wrapper {
  width: 100%;
  max-width: 600px;
  padding: 40px 20px;
  margin: 0 auto;
  min-height: calc(100vh - 130px);
  display: flex;
  align-items: center;
}

.content {
  min-height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 16px;
}

.icon {
  width: 60px;
  height: 60px;
  color: var(--text-color-brand);
  border: 3px solid currentcolor;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  line-height: 1;
  font-size: 30px;
  font-weight: bold;
}

.button {
  margin-top: 40px;
}

@include small-screen {
  .wrapper {
    padding: 40px;
    min-height: 300px;
  }
}
